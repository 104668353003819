export default {
  name: "Forbidden",
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    helpCenterUrl() {
      return process.env.VUE_APP_HELP_CENTER_URL
    }
  },
  watch: {
  }
}
